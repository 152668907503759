import React, { useEffect, useState } from 'react';

import { QuestionCircle as ContactToGetPriceIcon } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { CartAddBtn } from '../../components';
import { WHATSAPP_NUMBER } from '../../constansts';
import { TransportReversationByWhatsappBtn } from '../../containers';

import './car-price.scss';
function CarPrice({ transport }) {
  const { t } = useTranslation();
  const [image, setImage] = useState();
  useEffect(() => {
    import(`../../assets/cars/${transport.car.imageUrl[0]}`).then((image) =>
      setImage(() => {
        return image.default;
      }),
    );
  }, [transport.car.imageUrl]);
  return (
    <div className="col-12 col-lg-4">
      <div className="car-price-container bg-white rounded d-flex flex-column h-100 ">
        <div style={{ flexGrow: 1 }} className="d-flex align-items-center">
          <LazyLoadImage src={image} alt={`car.${transport.car.model}`} />
        </div>
        <h1 className="fs-3  text-secondary px-3">{t(`car.${transport.car.model}`)}</h1>
        <h2 className="fs-4 text-secondary px-3">{t(`category.${transport.car.category}`)}</h2>
        <div className="container-fluid p-3">
          <div className="row">
            <div className="col">
              {t('content.transport-for-car-price')}:<span className="fs-1 fw-bold px-3">{transport.price ? `${transport.price} ${t('content.currency')}` : <ContactToGetPriceIcon />}</span>
              <div className="fs-4 fw-bold text-center text-success">{t('content.tax-free')}</div>
            </div>
            <div className="col">
              {t('content.transport-for-car-down-payment')}:<span className="fs-1 fw-bold px-3">{transport.downPayment}</span>
              {t('content.currency')}
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <CartAddBtn classname="btn-secondary" transport={transport} />
            </div>
            <div className="col-4"></div>
            <div className="col-4">
              <TransportReversationByWhatsappBtn classname="btn-outline-secondary" transport={transport} tel={WHATSAPP_NUMBER} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarPrice;

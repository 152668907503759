import React from 'react';

import { Whatsapp as WhatsappIcon } from 'react-bootstrap-icons';

import { getResourceInNewTab, cleanPhone } from '../../../fn';

function WhatsappBtn({ caption = 'Whatsapp', tel, message = '', classname = null }) {
  return (
    <button
      className={`btn ${classname} d-flex align-items-center w-100`}
      onClick={() => {
        getResourceInNewTab(`https://api.whatsapp.com/send/?phone=${cleanPhone(tel)}&text=${encodeURIComponent(message)}`);
      }}
    >
    
        <WhatsappIcon size={28} />
        <span className={`${caption ? 'fs-5 px-3' : ''}`}>{caption}</span>
  
    </button>
  );
}

export default WhatsappBtn;

import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import cityAlmadinah from '../../../assets/imgs/almadinah_almunawara_section.jpg';
import cityJida from '../../../assets/imgs/jida_section.jpg';
import cityMaka from '../../../assets/imgs/maka_almukarama_section.jpg';
import { useIntersectionObserver } from '../../../hooks';
import './transport-service-section.scss';

function TransportServiceSection() {
  const ref1 = useRef();
  const refEntry1 = useIntersectionObserver(ref1, {});

  const ref2 = useRef();
  const refEntry2 = useIntersectionObserver(ref2, {});

  const ref3 = useRef();
  const refEntry3 = useIntersectionObserver(ref3, {});
  const { t } = useTranslation();
  return (
    <section className="transport-service-container">
      <div className="container-fluid text-center bg-primary">
        <div className="row g-2">
          <div ref={ref1} className={`col-md-4 transport-service-container__card ${refEntry1?.isIntersecting ? 'hover' : ''}`}>
            <div className="transport-service-container__card__text">
              <h1>{t('city.4')}</h1>
              <p>
                {t('transport-type.transport')} / {t('transport-type.tour')}
              </p>
            </div>
            <div className="transport-service-container__card__img-container rounded">
              <LazyLoadImage src={cityMaka} alt={t('content.maka-alt')} />
            </div>
          </div>
          <div ref={ref2} className={`col-md-4 transport-service-container__card ${refEntry2?.isIntersecting ? 'hover' : ''}`}>
            <div className="transport-service-container__card__text px-3">
              <h1>{t('city.3')}</h1>
              <p>
                {t('transport-type.transport')} / {t('transport-type.tour')}
              </p>
            </div>
            <div className="transport-service-container__card__img-container">
              <LazyLoadImage src={cityAlmadinah} alt={t('content.almadinah-almunawara-alt')} />
            </div>
          </div>
          <div ref={ref3} className={`col-md-4 transport-service-container__card ${refEntry3?.isIntersecting ? 'hover' : ''}`}>
            <div className="transport-service-container__card__text">
              <h1>{t('city.2')}</h1>
              <p>{t('transport-type.transport')}</p>
            </div>
            <div className="transport-service-container__card__img-container">
              <LazyLoadImage src={cityJida} alt={t('content.jida-alt')} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TransportServiceSection;

import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { ScrollToTop, TopPaddingSection, AppHeaderSection } from '../../components';
import { Breadcrumb, ContactSection, FooterSection } from '../../containers';

function FrequentlyQuestions() {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${t('app.title')} - ${t('title.freq')}`;
  }, [t]);
  return (
    <>
      <ScrollToTop />
      <AppHeaderSection />
      <TopPaddingSection />
      <main>
        <Breadcrumb>
          <div>
            <h1 className="text-secondary px-3">{t('title.freq')}</h1>
          </div>
        </Breadcrumb>
        <section className="frequently-questions-section">
          <div className="container text-white">
            {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
              <div key={`freq-${index}`}>
                <div className="fs-3">&#x27A3; {t(`freq.freq${index}.q`)}</div>
                <div className="fs-5 px-3 mb-5">{t(`freq.freq${index}.a`)}</div>
              </div>
            ))}
          </div>
        </section>
        <ContactSection />
      </main>
      <FooterSection />
    </>
  );
}

export default FrequentlyQuestions;

import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { ScrollToTop, TopPaddingSection, AppHeaderSection, TransportServiceSection } from '../../components';
import { Breadcrumb, ContactSection, HotelReservationSection, CarGallarySection, FromToGallarySection, FooterSection } from '../../containers';

function CarsPage() {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${t('app.title')} - ${t('title.cars-page')}`;
  }, [t]);
  return (
    <>
      <ScrollToTop />
      <AppHeaderSection />
      <TopPaddingSection />
      <main>
        <Breadcrumb>
          <div>
            <h1 className="text-secondary px-3">{t('title.cars-page')}</h1>
          </div>
        </Breadcrumb>
        <CarGallarySection />
        <TransportServiceSection />
        <ContactSection />
        <FromToGallarySection />
        <br />
        <HotelReservationSection />
      </main>
      <FooterSection />
    </>
  );
}

export default CarsPage;

import React from 'react';

import { House as HomeIcon, CarFrontFill as CarIcon, Bag as TransportsIcon, QuestionCircle as FrequentlyQuestionsIcon, GlobeEuropeAfrica as LanguageIcon } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

import LogoImage from '../../../assets/imgs/logo.png';
import { CartIndicator } from '../../../components';

function AppHeaderSection() {
  const { t, i18n } = useTranslation();
  return (
    <header>
      <nav className="navbar navbar-dark bg-dark fixed-top px-0">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
          <LazyLoadImage className="mx-2" src={LogoImage} alt={t('app.brand')} width={48}/>
            {t('app.brand')}
          </Link>
          <div className="d-flex align-items-center gap-3 ">
            <CartIndicator></CartIndicator>
            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#app-canvas-id" aria-controls="app-canvas-id" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div className={`offcanvas ${i18n.language === 'en' ? 'offcanvas-start' : 'offcanvas-end'} text-bg-dark`} tabIndex="-1" id="app-canvas-id" aria-labelledby="app-canvas-label">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="app-canvas-label">
                {t('app.brand')}
              </h5>
              <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <ul className="nav nav-pills flex-column mb-auto">
                <li className="nav-item">
                  <Link to="/" className="nav-link text-white" aria-current="page">
                    <HomeIcon size={18} />
                    &nbsp;&nbsp;
                    {t('title.home-page')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/car-all-page" className="nav-link text-white" aria-current="page">
                    <CarIcon size={18} />
                    &nbsp;&nbsp;
                    {t('title.cars-page')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/cart/from-to-all-page" className="nav-link text-white" aria-current="page">
                    <TransportsIcon size={18} />
                    &nbsp;&nbsp;
                    {t('title.tansport-page')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/freq" className="nav-link text-white" aria-current="page">
                    <FrequentlyQuestionsIcon size={18} />
                    &nbsp;&nbsp;
                    {t('title.freq')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link text-white"
                    aria-current="page"
                    onClick={(e) => {
                      e.preventDefault();
                      const toLanguage = i18n.language === 'en' ? 'ar' : 'en';
                      i18n.changeLanguage(toLanguage);
                      document.querySelector('.btn-close')?.click();
                    }}
                  >
                    <LanguageIcon size={18} />
                    &nbsp;&nbsp;
                    {t('app.language')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
export default AppHeaderSection;

import React from 'react';

import { useTranslation } from 'react-i18next';

import { WhatsappBtn, PhoneCallBtn } from '../../components';
import { WHATSAPP_NUMBER } from '../../constansts';

import './rent-car-section.scss';

function RentCarSection() {
  const { t } = useTranslation();
  return (
    <section className="rent-car-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex justify-content-center">
            <div>
              <WhatsappBtn classname="btn-success mb-3 fs-6" tel={WHATSAPP_NUMBER} caption={t('content.car-rent-react-out-12-btn-caption')} message={t('messages.car-reservation-12')} />
              <WhatsappBtn classname="btn-success mb-3 fs-6" tel={WHATSAPP_NUMBER} caption={t('content.car-rent-react-out-24-btn-caption')} message={t('messages.car-reservation-24')} />
              <WhatsappBtn classname="btn-success mb-3 fs-6" tel={WHATSAPP_NUMBER} caption={t('content.car-rent-react-out-day-btn-caption')} message={t('messages.car-reservation-day')} />
              <PhoneCallBtn classname="btn-primary border border-white fs-6" tel={WHATSAPP_NUMBER} caption={t('content.car-rent-call-btn-caption')} />
            </div>
          </div>
          <div className="col-lg-6 mt-3">
            <p className="fs-1 text-center text-white">{t('content.car-reversation-advantage')}</p>
            <h1 className="fs-1 text-center text-white">{t('content.car-reversation-title')}</h1>
          </div>
        </div>
      </div>
    </section>
  );
}
export default RentCarSection;

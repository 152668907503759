import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import CartContextProvider from './contexts/CartContex';
import { HomePage, CarsPage, CarPage, FromToPage, FromToAllPage, FrequentlyQuestions } from './pages';
import './i18n';
import './app.scss';
import CartPage from './pages/cart-page/CartPage';

function App() {
  const { t, i18n } = useTranslation();
  let language = i18n.language;
  let title = t('app.title');
  let direction = t('app.direction');

  useEffect(() => {
    document.title = title;
    document.documentElement.lang = language;
    document.documentElement.dir = direction;
  }, [title, language, direction]);
  return (
    <CartContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/car-all-page" element={<CarsPage />} />
          <Route path="/cart/car-page" element={<CarPage />} />
          <Route path="/cart/from-to-page" element={<FromToPage />} />
          <Route path="/cart/from-to-all-page" element={<FromToAllPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/freq" element={<FrequentlyQuestions />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </Router>
    </CartContextProvider>
  );
}

export default App;

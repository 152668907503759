import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { SelectTransportForCarBtn } from '../../components';
import { WHATSAPP_NUMBER } from '../../constansts';
import { CarReversationByWhatsappBtn } from '../../containers';
import './car-gallary-item.scss';

function CarGallaryItem({ car }) {
  const { t } = useTranslation();

  const [image, setImage] = useState();
  useEffect(() => {
    import(`../../assets/cars/${car.imageUrl[0]}`).then((image) =>
      setImage(() => {
        return image.default;
      }),
    );
  }, [car.imageUrl]);
  return (
    <article className={`car-gallary-item d-flex align-items-center h-100 rounded`}>
      <div className="car-gallary-item__container">
        <LazyLoadImage src={image} alt={`car.${car.model}`} />
      </div>
      <div className="car-gallary-item__actions w-100 ">
        <h1 className="fs-1 fw-bold px-3 d-none d-lg-block ">{t(`category.${car.category}`)}</h1>
        <h2 className="fs-2 d-none px-3 d-lg-block"> {t(`car.${car.model}`)}</h2>

        <h1 className="fs-5 fw-bold px-3 d-block d-lg-none">{t(`category.${car.category}`)}</h1>
        <h2 className="fs-6 d-block px-3 d-lg-none">{t(`car.${car.model}`)}</h2>
        <div className="container-fluid">
          <div className="row">
            <div className="col-5 col-md-3">
              <CarReversationByWhatsappBtn classname="btn-outline-secondary justify-content-center" car={car} tel={WHATSAPP_NUMBER} />
            </div>
            <div className="col-2 col-md-6"></div>
            <div className="col-5 col-md-3">
              <SelectTransportForCarBtn classname="btn-secondary" car={car} />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default CarGallaryItem;

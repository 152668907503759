import React from 'react';

import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import LogoImage from '../../assets/imgs/logo.png';
import MaroufImage from '../../assets/imgs/marouf_logo.svg';
import VatImage from '../../assets/imgs/vat.webp';
import { SocialMediaBtn } from '../../components';
import { MAROUF_URL, COMMERCIAL_REGISTER, TAX_IDENTIFICATION } from '../../constansts';
import { getResourceInNewTab } from '../../fn';
import { socialMediaTiktok, socialMediaTiwtter, socialMediaInstagram, socialMediaYoutube, socialMediaGmail } from '../../iconHelper';

import './footer-section.scss';

function FooterSection() {
  const { t } = useTranslation();
  return (
    <footer className="container-fluid border-top mt-3 pt-3">
      <div className="d-flex justify-content-between align-items-center">
        <div className="cerrificated-container">
          <LazyLoadImage className="mx-2" src={LogoImage} alt={t('app.title')} width={48} />
        </div>
        <div className="cerrificated-container">
          <LazyLoadImage
            onClick={() => {
              getResourceInNewTab(MAROUF_URL);
            }}
            className="mx-5"
            style={{ filter: 'grayscale(0%)' }}
            src={MaroufImage}
            alt={t('content.marouf')}
          />
          <LazyLoadImage className="" src={VatImage} alt={t('content.vat')} />
        </div>
      </div>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-lg-4"></div>
          <div className="col-lg-4 text-center text-white">
            <div>
              {t('content.commercial-register')}: {COMMERCIAL_REGISTER}
            </div>
            <div>
              {t('content.tax-identification')}: {TAX_IDENTIFICATION}
            </div>
          </div>
          <div className="col-lg-4"></div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <div className="d-flex  gap-3 ">
          <SocialMediaBtn data={socialMediaTiktok} />
          <SocialMediaBtn data={socialMediaTiwtter} />
          <SocialMediaBtn data={socialMediaInstagram} />
          <SocialMediaBtn data={socialMediaYoutube} />
          <SocialMediaBtn data={socialMediaGmail} />
        </div>
      </div>

      <div className="text-white text-center small mt-3 pb-3">
        &copy; {new Date().getFullYear()} &ndash; {t('app.title')}
      </div>
    </footer>
  );
}

export default FooterSection;

import React from 'react';

import { Tiktok as TiktokIcon, Twitter as TiwtterIcon, Instagram as InstagrmIcon, Youtube as YoutubeIcon, Envelope as GmailIcon } from 'react-bootstrap-icons';
import { Building as CityIcon, BuildingUp as CityNourthIcon, BuildingDown as CitySouthIcon, Airplane as AirportIcon, TrainFront as TrainStationIcon, Flower3 as ShrinesIcon, Box2Fill as HaramIcon, Buildings as HotelIcon } from 'react-bootstrap-icons';

import { TIKTOK_URL, TWITTER_URL, INSTAGRAM_URL, YOUTUBE_URL, GMAIL_URL } from './constansts';
import { transportService } from './services';

const socialMediaTiktok = Object.freeze({
  profileUrl: TIKTOK_URL,
  icon: <TiktokIcon />,
});

const socialMediaTiwtter = Object.freeze({
  profileUrl: TWITTER_URL,
  icon: <TiwtterIcon />,
});

const socialMediaInstagram = Object.freeze({
  profileUrl: INSTAGRAM_URL,
  icon: <InstagrmIcon />,
});

const socialMediaYoutube = Object.freeze({
  profileUrl: YOUTUBE_URL,
  icon: <YoutubeIcon />,
});

const socialMediaGmail = Object.freeze({
  profileUrl: GMAIL_URL,
  icon: <GmailIcon />,
});

const getPlaceIcon = (index) => {
  switch (index) {
    case transportService.places.CITY:
      return CityIcon;
    case transportService.places.NORTH:
      return CityNourthIcon;
    case transportService.places.SOUTH:
      return CitySouthIcon;
    case transportService.places.AIRPORT:
      return AirportIcon;
    case transportService.places.STATION:
      return TrainStationIcon;
    case transportService.places.SHRINES:
      return ShrinesIcon;
    case transportService.places.HARAM:
      return HaramIcon;
    case transportService.places.HOTEL:
      return HotelIcon;
    default:
      return CityIcon;
  }
};

export { socialMediaTiktok, socialMediaTiwtter, socialMediaInstagram, socialMediaYoutube, socialMediaGmail };
export { getPlaceIcon };

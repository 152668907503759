import React from 'react';

import { HouseUp as BackIcon } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

function GotoBtn({ classname, to }) {
  const navigate = useNavigate();
  return (
    <button
      className={`btn ${classname} d-flex justify-content-center align-items-center w-100`}
      onClick={() => {
        navigate(to);
      }}
    >
      <BackIcon size={28} />
    </button>
  );
}
export default GotoBtn;

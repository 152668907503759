import React from 'react';

import { useTranslation } from 'react-i18next';

import { WhatsappBtn, PhoneCallBtn, PhoneCopyBtn } from '../../components';
import { WHATSAPP_NUMBER } from '../../constansts';

import './contact-section.scss';

function ContactSection() {
  const { t } = useTranslation();
  return (
    <section className="contact-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4">
            <WhatsappBtn classname={'justify-content-center btn-success p-2 my-2'} caption={t('content.contact-reach-out-btn-caption')} tel={WHATSAPP_NUMBER} message={t('messages.contact-reach-out')} />
          </div>
          <div className="col-lg-4">
            <PhoneCallBtn classname={'btn-flashy p-2 my-2'} caption={t('content.contact-call-btn-caption')} tel={WHATSAPP_NUMBER} />
          </div>
          <div className="col-lg-4">
            <PhoneCopyBtn classname={'btn-secondary p-2 my-2'} tel={WHATSAPP_NUMBER} />
          </div>
        </div>
      </div>
    </section>
  );
}
export default ContactSection;

import React, { useState, useEffect } from 'react';

import { CarGallaryItem } from '../../containers';
import { carService } from '../../services';

function CarGallarySection() {
  const [cars, setCars] = useState([]);

  useEffect(() => {
    setCars(carService.all);
  }, []);
  return (
    <section className="cars-gallary-container bg-white mt-3 pb-3">
      <div className="container-fluid">
        <div className="row g-3">
          {cars.map((car, index) => (
            <div key={index} className="col-6 col-lg-4">
              <CarGallaryItem key={`car-simple-item-${car.model}`} car={car} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
export default CarGallarySection;

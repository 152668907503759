import { CART_STORAGE_KEY } from '../constansts';

const cartOperation = Object.freeze({
  add: 'ADD_ITEM',
  remove: 'REMOVE_ITEM',
  checkOut: 'CHECK_OUT',
  clear: 'CLEAR',
});

const cartSumary = (items) => {
  localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(items));
  const count = items.reduce((t, item) => ++t, 0);
  const total = items.reduce((t, item) => (t += item.price), 0);
  return { count, total };
};
const cartReducer = (state, action) => {
  switch (action.type) {
    case cartOperation.add:
      if (!state.lineItems.find((item) => item.transportKey === action.payload.transportKey && item.model === action.payload.model)) {
        state.lineItems.push({ ...action.payload });
      }
      return {
        ...state,
        ...cartSumary(state.lineItems),
      };
    case cartOperation.remove:
      const items = [...state.lineItems.filter((item) => !(item.transportKey === action.payload.transportKey && item.model === action.payload.model))];
      return {
        lineItems: items,
        ...cartSumary(items),
      };
    case cartOperation.clear:
      return {
        lineItems: [],
        ...cartSumary([]),
      };
    case cartOperation.checkOut:
      return {
        lineItems: [],
        checkOut: true,
        ...cartSumary([]),
      };
    default:
      return state;
  }
};

export default cartReducer;
export { cartOperation, cartSumary };

import carService from './carService';

const factory = function () {
  function citiesFactory() {
    return Object.freeze({
      EABDALMALIK: 0,
      TAIF: 1,
      JIDA: 2,
      MADINA: 3,
      MAKA: 4,
    });
  }

  function placesFactory() {
    return Object.freeze({
      SHRINES: 0,
      STATION: 1,
      AIRPORT: 2,
      SOUTH: 3,
      NORTH: 4,
      CITY: 5,
      HARAM: 6,
      HOTEL: 7,
    });
  }

  function transportTypeFactory() {
    return Object.freeze({
      TRANSPORT: 0,
      TOUR: 1,
    });
  }
  const cities = citiesFactory();
  const places = placesFactory();
  const transportType = transportTypeFactory();

  let transportRawArray = [];
  let car;

  function addTransportRaw(car, from, fromPlace, to, toPlace, price, downPayment, type = transportType.TRANSPORT) {
    transportRawArray.push({
      key: (type * 1000 + (from + 1) * 10 + (fromPlace + 1)) * 100 + ((to + 1) * 10 + (toPlace + 1)),
      car,
      from,
      fromPlace,
      to,
      toPlace,
      price,
      downPayment,
      type,
    });
  }


  //-------------
  car = carService.model(carService.models.FORDTARUS2023);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MAKA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.JIDA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MAKA, places.CITY, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.MAKA, places.STATION, cities.MAKA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MADINA, places.STATION, cities.MADINA, places.HOTEL, 0, 50);
  //-------------
  car = carService.model(carService.models.GMCYUKON2023);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MAKA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.JIDA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MAKA, places.CITY, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.MAKA, places.STATION, cities.MAKA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MADINA, places.STATION, cities.MADINA, places.HOTEL, 0, 50);
  //-------------
  car = carService.model(carService.models.H1);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MAKA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.JIDA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MAKA, places.CITY, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.MAKA, places.STATION, cities.MAKA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MADINA, places.STATION, cities.MADINA, places.HOTEL, 0, 50);
  //-------------
  car = carService.model(carService.models.HIACEDOME2023);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MAKA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.JIDA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MAKA, places.CITY, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.MAKA, places.STATION, cities.MAKA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MADINA, places.STATION, cities.MADINA, places.HOTEL, 0, 50);
  //-------------
  car = carService.model(carService.models.LEXUSES2502023);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MAKA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.JIDA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MAKA, places.CITY, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.MAKA, places.STATION, cities.MAKA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MADINA, places.STATION, cities.MADINA, places.HOTEL, 0, 50);
  //-------------
  car = carService.model(carService.models.STARAYA2023);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MAKA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.JIDA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MAKA, places.CITY, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.MAKA, places.STATION, cities.MAKA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MADINA, places.STATION, cities.MADINA, places.HOTEL, 0, 50);
  //-------------
  car = carService.model(carService.models.SUBURBAN2023);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MAKA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.JIDA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MAKA, places.CITY, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.MAKA, places.STATION, cities.MAKA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MADINA, places.STATION, cities.MADINA, places.HOTEL, 0, 50);
  //-------------
  car = carService.model(carService.models.SONATA);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MAKA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.JIDA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MAKA, places.CITY, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.MAKA, places.STATION, cities.MAKA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MADINA, places.STATION, cities.MADINA, places.HOTEL, 0, 50);
  //-------------
  car = carService.model(carService.models.MERCEDESS5502023);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MAKA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.JIDA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MAKA, places.CITY, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.MAKA, places.STATION, cities.MAKA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MADINA, places.STATION, cities.MADINA, places.HOTEL, 0, 50);
  //-------------
  car = carService.model(carService.models.MERCEDESSPRINTER);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MAKA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.JIDA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MAKA, places.CITY, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.MAKA, places.STATION, cities.MAKA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MADINA, places.STATION, cities.MADINA, places.HOTEL, 0, 50);
  //-------------
  car = carService.model(carService.models.MERCEDESVITO);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MAKA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.JIDA, places.AIRPORT, cities.JIDA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MAKA, places.CITY, cities.MADINA, places.CITY, 0, 50);
  addTransportRaw(car, cities.MAKA, places.STATION, cities.MAKA, places.HOTEL, 0, 50);
  addTransportRaw(car, cities.MADINA, places.STATION, cities.MADINA, places.HOTEL, 0, 50);

  console.log(transportRawArray);
  const sortBy = (transport1, transport2) => {
    if (transport1.key === transport2.key) {
      return 0;
    }
    return transport1.key < transport2.key ? -1 : 1;
  };
  const GetFromToList = () => {
    const keys = [];
    const result = [];
    for (let index = 0; index < transportRawArray.length; index++) {
      const item = transportRawArray[index];
      if (!keys[item.key]) {
        keys[item.key] = item.key;
        result.push(
          Object.freeze({
            key: item.key,
            from: item.from,
            fromPlace: item.fromPlace,
            to: item.to,
            toPlace: item.toPlace,
            type: item.type,
          }),
        );
      }
    }
    return result.sort(sortBy);
  };

  const transportServiceResult = Object.freeze({
    cities,
    places,
    transportType,
    get raw() {
      return [...transportRawArray];
    },
    rawForCar: (car) => {
      return Object.freeze(transportRawArray.filter((item) => item.car.model === car.model).sort(sortBy));
    },
    rawForFromTo: (key) => {
      return Object.freeze(transportRawArray.filter((item) => item.key === key).sort(sortBy));
    },
    //note: there is no price
    fromTo: GetFromToList(),
  });
  return transportServiceResult;
};
const transportService = factory();
export default transportService;

import React, { createContext, useReducer } from 'react';

import cartReducer, { cartOperation, cartSumary } from './CartReducer';
import { CART_STORAGE_KEY } from '../constansts';

const lineItems = localStorage.getItem(CART_STORAGE_KEY) ? JSON.parse(localStorage.getItem(CART_STORAGE_KEY)) : [];

const initialState = {
  lineItems,
  checkOut: false,
  ...cartSumary(lineItems),
};
export const CartContext = createContext();

const CartContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  const addCartItemLine = (payload) => {
    dispatch({ type: cartOperation.add, payload });
  };

  const removeCartItemLine = (payload) => {
    dispatch({ type: cartOperation.remove, payload });
  };

  const clearCart = () => {
    dispatch({ type: cartOperation.clear });
  };

  const handleCheckout = () => {
    console.log('CHECKOUT', state);
    dispatch({ type: cartOperation.checkOut });
  };

  const contextValues = {
    addCartItemLine,
    removeCartItemLine,
    clearCart,
    handleCheckout,
    ...state,
  };

  return <CartContext.Provider value={contextValues}>{children}</CartContext.Provider>;
};

export default CartContextProvider;

import React, { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, Navigate, Link } from 'react-router-dom';

import { ScrollToTop, AppHeaderSection, TopPaddingSection } from '../../components';
import { ContactSection, FooterSection, CarPrice, Breadcrumb } from '../../containers';
import { transportService } from '../../services';

function FromToPage() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const key = state?.fromTo?.key || -1;
  const transports = useRef(transportService.rawForFromTo(key));
  useEffect(() => {
    document.title = `${t('app.title')} - ${t('title.tansport-page')}`;
  }, [t]);
  const isValid = typeof state === 'object' && state !== null && 'fromTo' in state ? true : false;
  if (!isValid) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <ScrollToTop />
      <AppHeaderSection />
      <TopPaddingSection />
      <main>
        <Breadcrumb>
          <div>
            <h1 className="text-secondary px-3">
              {t(`city.${state.fromTo.from}`)} - {t(`place.${state.fromTo.fromPlace}`)}
            </h1>
            <h2 className="text-secondary px-3">
              &crarr; {t(`city.${state.fromTo.to}`)} - {t(`place.${state.fromTo.toPlace}`)}
            </h2>
          </div>
          <div className="d-flex justify-content-end px-3">
            <Link className="text-secondary" to="/cart/from-to-all-page">
              {t('content.all')}
            </Link>
          </div>
        </Breadcrumb>
        <section>
          <div className="container-fluid">
            <div className="row g-3">
              {transports.current.map((transport, index) => {
                return <CarPrice key={`cars-${index}`} transport={transport} />;
              })}
            </div>
          </div>
        </section>
        <ContactSection />
      </main>
      <FooterSection />
    </>
  );
}

export default FromToPage;

import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { CartRemoveBtn } from '../../components';
import { transportService } from '../../services';

function CartItem({ index, transport }) {
  const isTransport = useRef(transport.type === transportService.transportType.TRANSPORT);
  const { t } = useTranslation();
  return (
    <div className="container-fluid pb-3">
      <div className="row">
        <div className="col-1 fs-4 text-white text-center">{index}</div>
        <div className="col-11">
          <div className="text-secondary fw-bold fs-5 badge rounded p-1">{t(`car.${transport.model}`)}</div>
          <div className="text-white fs-5">
            {t(`city.${transport.from}`)} - {t(`place.${transport.fromPlace}`)}
          </div>
          {isTransport.current ? (
            <div className="text-white fs-5 ">
              &crarr; {t(`city.${transport.to}`)} - {t(`place.${transport.toPlace}`)}
            </div>
          ) : (
            <span className="text-white">{t('content.transport-type-tour')}</span>
          )}
          <div className="row">
            <div className="col-3">
              <CartRemoveBtn classname="btn-danger" transport={transport} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartItem;

import React from 'react';

import { TelephoneOutboundFill as CallIcon } from 'react-bootstrap-icons';

import { getResourceInNewTab, cleanPhone } from '../../../fn';

function PhoneCallBtn({ caption = 'Whatsapp', tel, classname = null }) {
  return (
    <button
      className={`btn  ${classname} w-100`}
      onClick={() => {
        getResourceInNewTab(`tel:${cleanPhone(tel)}`);
      }}
    >
      <CallIcon size={28} />
      <span className={'fs-5 px-3'}>{caption}</span>
    </button>
  );
}

export default PhoneCallBtn;

import React, { useEffect, useState } from 'react';

import { CartPlus as CanAddIcon, CartCheckFill as AddedIcon } from 'react-bootstrap-icons';

import { useCart } from '../../../hooks';

function CartAddBtn({ classname = '', transport }) {
  let globalState = useCart();
  let { addCartItemLine, removeCartItemLine } = globalState;
  const [isAdded, setIsAdded] = useState(!!globalState.lineItems.find((i) => i.transportKey === transport.transportKey && i.model === transport.car.model));

  useEffect(() => {
    setIsAdded(!!globalState.lineItems.find((i) => i.transportKey === transport.key && i.model === transport.car.model));
  }, [globalState, transport.car.model, transport.key, transport.transportKey]);

  return (
    <button
      className={`btn ${classname} d-flex justify-content-center align-items-center w-100`}
      onClick={() => {
        if (isAdded) {
          removeCartItemLine({
            transportKey: transport.key,
            type: transport.type,
            from: transport.from,
            fromPlace: transport.fromPlace,
            to: transport.to,
            toPlace: transport.toPlace,
            price: transport.price,
            downPayment: transport.downPayment,
            model: transport.car.model,
          });
          return;
        }
        addCartItemLine({
          transportKey: transport.key,
          type: transport.type,
          from: transport.from,
          fromPlace: transport.fromPlace,
          to: transport.to,
          toPlace: transport.toPlace,
          price: transport.price,
          downPayment: transport.downPayment,
          model: transport.car.model,
        });
      }}
    >
      {isAdded ? <AddedIcon size={28} /> : <CanAddIcon size={28} />}
    </button>
  );
}

export default CartAddBtn;

import React from 'react';

import { Magic, CalendarHeartFill, EmojiHeartEyes, ThermometerSun, Wifi, Coin, Sunglasses, CalendarCheck } from 'react-bootstrap-icons';
import './advantages-section.scss';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import logoImage from '../../../assets/imgs/logo.png';

function AdvantagesSection() {
  const { t } = useTranslation();
  return (
    <section className="advantages-section-container">
      <LazyLoadImage className="py-4" src={logoImage} alt={`${t('app.brand')}`} />
      <div className="container-of-advantage py-5">
          <div className="advantage-item">
            <Magic className="display-4" />
            <p className="pt-2">{t('advantages.0')}</p>
          </div>
          <div className="advantage-item">
            <CalendarHeartFill className="display-4" />
            <p className="pt-2">{t('advantages.1')}</p>
          </div>
          <div className="advantage-item">
            <EmojiHeartEyes className="display-4" />
            <p className="pt-2">{t('advantages.2')}</p>
          </div>
          <div className="advantage-item">
            <ThermometerSun className="display-4" />
            <p className="pt-2">{t('advantages.3')}</p>
          </div>

          <div className="advantage-item">
            <Wifi className="display-4" />
            <p className="pt-2">{t('advantages.4')}</p>
          </div>
          <div className="advantage-item">
            <Coin className="display-4" />
            <p className="pt-2">{t('advantages.5')}</p>
          </div>
          <div className="advantage-item">
            <Sunglasses className="display-4" />
            <p className="pt-2">{t('advantages.6')}</p>
          </div>
          <div className="advantage-item">
            <CalendarCheck className="display-4" />
            <p className="pt-2">{t('advantages.7')}</p>
          </div>
      </div>
    </section>
  );
}

export default AdvantagesSection;

import React from 'react';

import { GotoBtn } from '../../components';

function Breadcrumb({ children }) {
  return (
    <aside className="container-fluid">
      <div className="row">
        <div className="col-2 col-md-1">
          <GotoBtn classname="btn-outline-secondary" to="/" />
        </div>
        <div className="col-10 col-md-11">{children}</div>
      </div>
    </aside>
  );
}

export default Breadcrumb;

import React from 'react';

import { useTranslation } from 'react-i18next';

import { WhatsappBtn } from '../../components';
import { stringFormat } from '../../fn';

function TransportReversationByWhatsappBtn({ transport, tel, classname = null }) {
  const { t } = useTranslation();
  const values = {
    model: t(`car.${transport.car.model}`),
    fromTo: `${t(`city.${transport.from}`)} (${t(`place.${transport.fromPlace}`)}) الى ${t(`city.${transport.to}`)} (${t(`place.${transport.toPlace}`)})`,
  };
  return <WhatsappBtn caption={''} tel={tel} classname={`${classname} justify-content-center`} message={stringFormat(t('messages.transport-reservation'), values)} />;
}
export default TransportReversationByWhatsappBtn;

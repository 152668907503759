import React, { useRef } from 'react';

import './from-to-gallary-section.scss';
import { useNavigate } from 'react-router-dom';

import { FromTo, FromToTour } from '../../containers';
import { transportService } from '../../services';

function FromToGallarySection() {
  const serviceForTransport = useRef(transportService);
  const navigate = useNavigate();
  return (
    <section className="transport-gallary-section">
      <div className="container-fluid mt-3">
        <div className="row g-3">
          {serviceForTransport.current.fromTo.map((fromTo, index) => {
            return fromTo.type === transportService.transportType.TRANSPORT ? (
              <FromTo key={index} fromTo={fromTo}>
                <div
                  className="from-to-gallary-item__link"
                  onClick={() => {
                    navigate('/cart/from-to-page', { state: { fromTo: fromTo } });
                  }}
                ></div>
              </FromTo>
            ) : (
              <FromToTour key={index} fromTo={fromTo}>
                <div
                  className="from-to-gallary-item__link"
                  onClick={() => {
                    console.log('test ..');
                    navigate('/cart/from-to-page', { state: { fromTo: fromTo } });
                  }}
                ></div>
              </FromToTour>
            );
          })}
        </div>
      </div>
    </section>
  );
}
export default FromToGallarySection;

import React from 'react';

import { useTranslation } from 'react-i18next';

import { WhatsappBtn, PhoneCallBtn } from '../../components';
import { WHATSAPP_NUMBER } from '../../constansts';

import './hotel-reservation-section.scss';

function HotelReservationSection() {
  const { t } = useTranslation();
  return (
    <section className="hotel-reservation-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ">
            <p className="fs-1 text-center text-white">{t('content.hotel-reversation-advantage')}</p>
            <h1 className="fs-2 text-center text-white">{t('content.hotel-reversation-title')}</h1>
          </div>
          <div className="col-lg-6 d-flex justify-content-center mt-3">
            <div >
              <WhatsappBtn classname="btn-success" tel={WHATSAPP_NUMBER} caption={t('content.hotel-reversation-reach-out-btn-caption')} message={t('messages.hotel-reservation')} />
              <PhoneCallBtn classname={'btn-primary my-2'} caption={t('content.contact-call-btn-caption')} tel={WHATSAPP_NUMBER} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default HotelReservationSection;

import React from 'react';

import { CartDash as RemoveIcon } from 'react-bootstrap-icons';

import { useCart } from '../../../hooks';

function CartRemoveBtn({ classname = '', transport }) {
  let { removeCartItemLine } = useCart();
  return (
    <button
      className={`btn ${classname} d-flex justify-content-center align-items-center w-100`}
      onClick={() => {
        removeCartItemLine({
          transportKey: transport.transportKey,
          type: transport.type,
          from: transport.from,
          fromPlace: transport.fromPlace,
          to: transport.to,
          toPlace: transport.toPlace,
          price: transport.price,
          downPayment: transport.downPayment,
          model: transport.model,
        });
      }}
    >
      <RemoveIcon size={28} />
    </button>
  );
}

export default CartRemoveBtn;

import React, { useState, useEffect } from 'react';

import { Cart as CartIcon, CartFill as CartFillIcon } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

import { useCart } from '../../../hooks';

function CartIndicator() {
  const globalState = useCart();
  const { count } = globalState;

  const [cartItemsCount, setCartItemsCoutn] = useState(count);

  useEffect(() => {
    const { count } = globalState;
    setCartItemsCoutn(count);
  }, [globalState]);
  return (
    <Link to="/cart">
      <div className="btn btn-outline-secondary w-100 position-relative">
        {cartItemsCount ? <CartFillIcon size={20} /> : <CartIcon size={20} />}
        <span style={{ width: '1.5em', height: '1.5em' }} className="position-absolute fw-bold top-0 start-100 translate-middle badge rounded-pill bg-secondary text-primary d-flex justify-content-center align-items-center">
          {cartItemsCount}
          <span className="visually-hidden">unread messages</span>
        </span>
      </div>
    </Link>
  );
}
export default CartIndicator;

import React from 'react';

import { QuestionCircle as ContactToGetPriceIcon } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

import { CartAddBtn } from '../../components';
import { WHATSAPP_NUMBER } from '../../constansts';
import { TransportReversationByWhatsappBtn, FromTo } from '../../containers';

function FromToPrice({ transport }) {
  const { t } = useTranslation();
  return (
    <FromTo fromTo={transport}>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            {t('content.transport-for-car-price')}:<span className="fs-1 fw-bold px-3">{transport.price ? `${transport.price} ${t('content.currency')}` : <ContactToGetPriceIcon />}</span>
            <div className="fs-4 fw-bold text-center text-success">{t('content.tax-free')}</div>
          </div>
          <div className="col">
            {t('content.transport-for-car-down-payment')}:<span className="fs-1 fw-bold px-3">{transport.downPayment}</span>
            {t('content.currency')}
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-5 col-md-3">
          <CartAddBtn classname="btn-secondary" transport={transport} />
        </div>
        <div className="col-2 col-md-6"></div>
        <div className="col-5 col-md-3">
          <TransportReversationByWhatsappBtn classname="btn-outline-secondary" transport={transport} tel={WHATSAPP_NUMBER} />
        </div>
      </div>
    </FromTo>
  );
}

export default FromToPrice;

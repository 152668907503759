import { useState, useEffect } from 'react';

import './car-images-gallary-section.scss';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function CarImagesGallarySection({ car }) {
  const { t } = useTranslation();
  const [images, setImages] = useState([]);
  useEffect(() => {
    car.imageUrl.forEach((url, i) => {
      import(`../../../assets/cars/${url}`).then((image) =>
        setImages((prev) => {
          prev[i] = image.default;
          return [...prev];
        }),
      );
    });
  }, [car]);

  return (
    <section className="car-images-gallary-section__container bg-white">
      {images.map((image, index) => {
        return <LazyLoadImage key={`car-image-${index}`} src={image} alt={t(`car.${car.model}`)} />;
      })}
    </section>
  );
}

export default CarImagesGallarySection;

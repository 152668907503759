const getRandomNumber = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const getResourceInNewTab = (url) => {
  const w = window.open(url, '_blank', 'noopener,noreferrer');
  if (w) {
    w.opener = null;
  }
};

const stringFormat = (stringTemplate, values) => {
  return stringTemplate.replace(/{(\w+)}/g, (placeholderWithDelimiters, placeholderWithoutDelimiters) => (values.hasOwnProperty(placeholderWithoutDelimiters) ? values[placeholderWithoutDelimiters] : placeholderWithDelimiters));
};

const cleanPhone = (tel) => {
  return tel.replace(/\s/g, '');
};

export { getRandomNumber, getResourceInNewTab, stringFormat, cleanPhone };

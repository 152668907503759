import React, { useState } from 'react';

import { PersonLinesFill as ContactInfoIcon, ClipboardCheckFill as ClipboardIcon } from 'react-bootstrap-icons';

function PhoneCopyBtn({ tel, classname = null }) {
  const [showCopy, setShowCopy] = useState(false);
  return (
    <button
      className={`btn ${classname} w-100`}
      onClick={() => {
        if (!(navigator && navigator.clipboard)) {
          return;
        }
        navigator.clipboard.writeText(tel).then(() => {
          setShowCopy(true);

          setTimeout(() => {
            setShowCopy(false);
          }, 1500);
        });
      }}
    >
      {showCopy && <ClipboardIcon size={28} />}
      {!showCopy && <ContactInfoIcon size={28} />}
      <span dir="ltr" className={'fs-5 px-3'}>
        {tel}
      </span>
    </button>
  );
}

export default PhoneCopyBtn;

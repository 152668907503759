const factory = function () {
  const carsList = [];
  const sortCar = (car1, car2) => {
    if (car1.order === car2.order) {
      return 0;
    }
    return car1.order < car2.order ? -1 : 1;
  };
  const addCar = function (model, category, minCapacity, maxCapacity, taxForhour, order, ...images) {
    const car = Object.freeze({
      model,
      category,
      minCapacity,
      maxCapacity,
      taxForhour,
      order,
      imageUrl: Object.freeze([...images]),
    });
    carsList[model] = car;
  };

  //categories
  const carsCategoriesFactory = function () {
    return Object.freeze({
      VIP: 0,
      VIPMED: 1,
      CLASICC: 2,
      FAMILY: 3,
      FAMILYMED: 4,
      SPECIALNEEDS: 5,
    });
  };
  const carsCategories = carsCategoriesFactory();
  const models = Object.freeze({
    FORDTARUS2023: 0,
    GMCYUKON2023: 1,
    H1: 2,
    HIACEDOME2023: 3,
    LEXUSES2502023: 4,
    MERCEDESS5502023: 5,
    STARAYA2023: 6,
    SUBURBAN2023: 7,
    SONATA: 8,
    MERCEDESSPRINTER: 9,
    MERCEDESVITO: 10,
  });
  addCar(models.MERCEDESSPRINTER, carsCategories.FAMILY, 1, 16, 150, 1, 'mercedes_sprinter/mercedes_sprinter_view.png',  'mercedes_sprinter/mercedes_sprinter_view1.jpeg', 'mercedes_sprinter/mercedes_sprinter_inner1.jpeg', 'mercedes_sprinter/mercedes_sprinter_inner2.jpeg', 'mercedes_sprinter/mercedes_sprinter_inner3.jpeg');
  addCar(models.MERCEDESVITO, carsCategories.FAMILY, 1, 9, 150, 2, 'mercedes_vito/mercedes_vito_view.jpg', 'mercedes_vito/mercedes_vito_inner1.jpeg', 'mercedes_vito/mercedes_vito_inner2.jpeg', 'mercedes_vito/mercedes_vito_inner3.jpeg');
  addCar(models.GMCYUKON2023, carsCategories.FAMILY, 1, 8, 150, 3, 'gmc_yukan/gmc_yukan_view.png', 'gmc_yukan/gmc_yukan_view1.png', 'gmc_yukan/gmc_yukan_inner1.jpeg', 'gmc_yukan/gmc_yukan_inner2.jpeg', 'gmc_yukan/gmc_yukan_inner3.jpeg');
  addCar(models.MERCEDESS5502023, carsCategories.VIP, 1, 4, 0, 4, 'mercedes/mercedes_view.webp', 'mercedes/mercedes_view1.jpeg', 'mercedes/mercedes_view2.jpeg', 'mercedes/mercedes_view3.jpeg', 'mercedes/mercedes_inner1.jpeg', 'mercedes/mercedes_inner2.jpeg');
  addCar(models.SUBURBAN2023, carsCategories.FAMILY, 1, 8, 150, 5, 'suburban/suburban_view.png', 'suburban/suburban_view1.jpeg', 'suburban/suburban_view2.jpeg', 'suburban/suburban_inner1.jpeg');
  addCar(models.STARAYA2023, carsCategories.FAMILY, 1, 8, 120, 6, 'staraya/staraya_view.png', 'staraya/staraya_inner1.jpeg', 'staraya/staraya_inner2.jpeg', 'staraya/staraya_inner3.jpeg');
  addCar(models.LEXUSES2502023, carsCategories.VIPMED, 1, 4, 200, 7, 'lexus/lexus_view.png', 'lexus/lexus_inner1.jpg', 'lexus/lexus_inner2.jpg');
  addCar(models.FORDTARUS2023, carsCategories.CLASICC, 1, 4, 120, 8, 'ford/ford_view.png', 'ford/ford_inner1.jpeg', 'ford/ford_inner2.jpeg', 'ford/ford_inner3.jpeg');
  addCar(models.SONATA, carsCategories.FAMILYMED, null, 4, 100, 9, 'sonata/sonata_view.png', 'sonata/sonata_inner1.jpeg', 'sonata/sonata_inner2.jpeg', 'sonata/sonata_inner3.jpeg');
  addCar(models.H1, carsCategories.SPECIALNEEDS, 1, 12, 200, 10, 'h1/h1_view.png', 'h1/h1_inner1.jpeg', 'h1/h1_inner2.jpeg', 'h1/h1_inner3.jpeg');
  addCar(models.HIACEDOME2023, carsCategories.FAMILY, 1, 12, 150, 11, 'hiace/hiace_view.png', 'hiace/hiace_view1.jpeg', 'hiace/hiace_inner1.jpeg', 'hiace/hiace_inner2.jpeg', 'hiace/hiace_inner3.jpeg');

  const carsByCategory = carsList.reduce((acc, car) => {
    if (!(acc[car.category] && Array.isArray(acc[car.category]))) {
      acc[car.category] = [];
    }
    acc[car.category].push(car);
    return acc;
  }, {});
  console.log(carsList);
  return Object.freeze({
    models,
    model: (model) => carsList.find((car) => car.model === model),
    groupedByCategory: Object.freeze(carsByCategory),
    all: Object.freeze(carsList.sort(sortCar)),
  });
};

const carService = factory();

export default carService;

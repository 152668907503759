import React from 'react';

import { useTranslation } from 'react-i18next';

import { WhatsappBtn } from '../../components';
import { stringFormat } from '../../fn';

function CarReversationByWhatsappBtn({ car, tel, classname = null }) {
  const { t } = useTranslation();
  const values = {
    model: t(`car.${car.model}`),
  };
  return <WhatsappBtn caption={''} tel={tel}  classname={`${classname} justify-content-center`} message={stringFormat(t('messages.car-reservation'), values)} />;
}
export default CarReversationByWhatsappBtn;

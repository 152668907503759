import React from 'react';

import { Cart2 as CartIcon } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

function SelectTransportForCarBtn({ classname, car }) {
  const navigate = useNavigate();
  return (
    <button
      className={`btn ${classname} d-flex justify-content-center align-items-center w-100`}
      onClick={() => {
        navigate('/cart/car-page', { state: { car } });
      }}
    >
      <CartIcon size={28} />
    </button>
  );
}
export default SelectTransportForCarBtn;

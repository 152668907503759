import React from 'react';

import { getResourceInNewTab } from '../../../fn';

function SocialMediaBtn({ data }) {
  const { profileUrl, icon } = data;
  return (
    <button
      className={`btn btn-outline-light rounded-circle d-flex justify-content-center align-items-center social-media-btn-size `}
      style={{ width: 'fit-content', aspectRatio: '1 / 1' }}
      onClick={() => {
        getResourceInNewTab(profileUrl);
      }}
    >
      {icon}
    </button>
  );
}

export default SocialMediaBtn;

import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, Navigate, Link } from 'react-router-dom';

import { ScrollToTop, AppHeaderSection, TopPaddingSection, CarImagesGallarySection } from '../../components';
import { Breadcrumb, ContactSection, TransportGallarySection, FooterSection } from '../../containers';

function CarPage() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const isValid = typeof state === 'object' && state !== null && 'car' in state ? true : false;
  useEffect(() => {
    if (!state?.car) {
      return;
    }
    document.title = `${t('app.title')} - ${t('title.car-page')} ${t(`car.${state.car.model}`)}`;
  }, [state?.car, state?.car?.model, t]);
  if (!isValid) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <ScrollToTop />
      <AppHeaderSection />
      <TopPaddingSection />
      <main>
        <Breadcrumb>
          <div>
            <h1 className="text-secondary px-3">{t(`car.${state.car.model}`)}</h1>
            <h2 className="text-secondary px-3">{t(`category.${state.car.category}`)}</h2>
          </div>
          <div className="d-flex justify-content-end px-3">
            <Link className="text-secondary" to="/car-all-page">
              {t('content.all')}
            </Link>
          </div>
        </Breadcrumb>
        <CarImagesGallarySection car={state.car} />
        <TransportGallarySection car={state.car} />
        <ContactSection />
      </main>
      <FooterSection />
    </>
  );
}
export default CarPage;

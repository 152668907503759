import React, { useState, useEffect } from 'react';

import { Cart as EmptyIcon, CarFrontFill as MoreIcon, CheckAll as SendIcon, SendFill as BusyIcon } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EMAIL_SENDER_URL } from '../../constansts';
import { CartItem } from '../../containers';
import { useCart } from '../../hooks';

function CartContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState(false);
  const [fullName, setFullName] = useState('');
  const [contactInformation, setContactInformation] = useState('');
  const [errorFullName, setErrorFullName] = useState(false);
  const [errorContactInformation, setErrorContactInformation] = useState(false);
  const globalState = useCart();

  const [lineItems, setLineItems] = useState(globalState.lineItems);
  useEffect(() => {
    setLineItems(globalState.lineItems);
  }, [globalState]);
  const submitHandler = (e) => {
    e.preventDefault();
    let isValid = true;
    if (fullName.length < 3) {
      setErrorFullName(true);
      isValid = false;
    }

    if (contactInformation.length < 8) {
      setErrorContactInformation(true);
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    let items = `<html dir="rtl">
<head>
<title>HTML email</title>
</head>
<body>
<p>${fullName}</p>
<p>${contactInformation}</p>
<table border="1">
<tr>
  <th>السيارة</th>
  <th>المصدر</th>
  <th>الوجهة</th>
</tr>`;

    items = globalState.lineItems.reduce((acc, lineItem) => {
      acc += '<tr>';
      acc += `<td>${t(`car.${lineItem.model}`)}</td>`;
      acc += `<td>${t(`city.${lineItem.from}`)}-${t(`place.${lineItem.fromPlace}`)}</td>`;
      acc += `<td>${t(`city.${lineItem.to}`)}-${t(`place.${lineItem.toPlace}`)}</td>`;
      acc += '</tr>';
      return acc;
    }, items);

    items += `</table>
    </body>
    </html>`;
    //send email to backend ...
    setIsBusy(true);
    fetch(EMAIL_SENDER_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ send: 'cart-send', fullname: fullName, contact: contactInformation, items }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json?.result) {
          globalState.clearCart();
          window.scrollTo(0, 0);
        }
      })
      .finally(() => {
        setIsBusy(false);
      });

    //navigate('/car-all-page');
  };
  return (
    <div className="mb-5">
      {globalState.count === 0 ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div>
            <EmptyIcon size={256} />
          </div>
          <div className="mt-5">
            <button
              className={'btn btn-secondary d-flex justify-content-center align-items-center '}
              onClick={() => {
                navigate('/car-all-page');
              }}
            >
              <MoreIcon size={28} /> &nbsp;
              {t('content.cart-browse')}
            </button>
          </div>
        </div>
      ) : (
        <div className="container pt-3">
          <div className="row text-white fw-bold border-bottom mb-3">
            <div className="col-1 text-white">#</div>
            <div className="col-11 text-white text-center">{t('content.cart-item-description')}</div>
          </div>
          <div className="row">
            {lineItems.map((lineItem, index) => (
              <CartItem key={`cart-item-${index}`} index={index + 1} transport={lineItem} />
            ))}
          </div>
          <div className="container">
             <div className="mb-3 row">
              <label htmlFor="full-name" className="col-sm-4 col-form-label text-white">
                {t('content.cart-full-name')}
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  autoComplete="off"
                  className={`form-control ${errorFullName ? 'border border-3 border-danger text-danger' : ''}`}
                  id="full-name"
                  name="full-name"
                  value={fullName}
                  onChange={(e) => {
                    setFullName(e.target.value);
                    setErrorFullName(false);
                  }}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="cantact-information" className="col-sm-4 col-form-label text-white">
                {t('content.cart-contact')}
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  autoComplete="off"
                  className={`form-control ${errorContactInformation ? 'border border-3 border-danger text-danger' : ''}`}
                  id="cantact-information"
                  name="cantact-information"
                  value={contactInformation}
                  onChange={(e) => {
                    setContactInformation(e.target.value);
                    setErrorContactInformation(false);
                  }}
                />
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-sm-4"></div>
                <div className="col-sm-8">
                  <button type="button" onClick={submitHandler} className={'btn btn-secondary d-flex justify-content-center align-items-center w-100'}>
                    {isBusy ? <BusyIcon size={18} /> : <SendIcon size={28} />} &nbsp;
                    {t('content.cart-send')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CartContainer;

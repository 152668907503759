import React from 'react';

import { Whatsapp as WhatsappIcon } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

import { WHATSAPP_NUMBER } from '../../../constansts';
import { getResourceInNewTab, cleanPhone } from '../../../fn';

import './hero-btn.scss';

function HeroBtn({ caption }) {
  const { t } = useTranslation();
  return (
    <button
      onClick={() => {
        getResourceInNewTab(`https://api.whatsapp.com/send/?phone=${cleanPhone(WHATSAPP_NUMBER)}&text=${encodeURIComponent(t('messages.hero-reach-out'))}`);
      }}
      className="hero-btn"
    >
      <span className="hero-btn__circle" aria-hidden="true">
        <span className="hero-btn__circle__icon hero-btn__circle__arrow"></span>
      </span>
      <span className="hero-btn__caption">
        <WhatsappIcon size={28} />
        {caption}
      </span>
    </button>
  );
}
export default HeroBtn;

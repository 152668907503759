import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { ScrollToTop, AppHeaderSection, AdvantagesSection, TransportServiceSection } from '../../components';
import { HeroSection, ContactSection, HotelReservationSection, RentCarSection, CarGallarySection, FromToGallarySection, FooterSection } from '../../containers';

function HomePage() {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${t('app.title')} - ${t('title.home-page')}`;
  }, [t]);
  return (
    <>
      <ScrollToTop />
      <AppHeaderSection />
      <main>
        <HeroSection />
        <ContactSection />
        <AdvantagesSection />
        <ContactSection />
        <CarGallarySection />
        <RentCarSection />
        <FromToGallarySection />
        <ContactSection />
        <TransportServiceSection />
        <ContactSection />
        <HotelReservationSection />
      </main>
      <FooterSection />
    </>
  );
}
export default HomePage;

import React, { Suspense } from 'react';

import ReactDOM from 'react-dom/client';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@popperjs/core';
import '../node_modules/bootstrap/js/dist/util';
import '../node_modules/bootstrap/js/dist/dom/data';
import '../node_modules/bootstrap/js/dist/dom/event-handler';
import '../node_modules/bootstrap/js/dist/dom/manipulator';
import '../node_modules/bootstrap/js/dist/dom/selector-engine';
import '../node_modules/bootstrap/js/dist/util/config';
import '../node_modules/bootstrap/js/dist/base-component';
import '../node_modules/bootstrap/js/dist/button';
import '../node_modules/bootstrap/js/dist/collapse';
import '../node_modules/bootstrap/js/dist/dropdown';
import '../node_modules/bootstrap/js/dist/offcanvas';
import '../node_modules/bootstrap/js/dist/tooltip';
import '../node_modules/bootstrap/js/dist/carousel';

import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<div>loading ...</div>}>
      <App />
    </Suspense>
  </React.StrictMode>,
);

reportWebVitals();

import React from 'react';

import { ArrowDownUp as TwoWayIcon } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

import { getPlaceIcon } from '../../iconHelper';

import './from-to.scss';

function FromTo({ children, fromTo }) {
  const { t } = useTranslation();
  const FromPlaceIcon = getPlaceIcon(fromTo.fromPlace);
  const ToPlaceIcon = getPlaceIcon(fromTo.toPlace);
  return (
    <div className="col-md-4">
      <div className="from-to-container position-relative">
        <div className={`container-fluid bg-white text-primary rounded pt-3 pb-3`}>
          <div className="row ">
            <div className="col-3 d-flex justify-content-center align-items-center">
              <FromPlaceIcon size={28} />
            </div>
            <div className="col-9">
              <h1 className="fs-5 text-center">{t(`city.${fromTo.from}`)}</h1>
              <h2 className="fs-5 text-center">{t(`place.${fromTo.fromPlace}`)}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center align-items-center">
              <TwoWayIcon size={28} />
            </div>
          </div>
          <div className="row">
            <div className="col-3 d-flex justify-content-center align-items-center">
              <ToPlaceIcon size={28} />
            </div>
            <div className="col-9">
              <h1 className="fs-5 text-center">{t(`city.${fromTo.to}`)}</h1>
              <h2 className="fs-5 text-center">{t(`place.${fromTo.toPlace}`)}</h2>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default FromTo;

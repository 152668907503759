import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import BackgroundImage3 from '../../assets/imgs/jida_airport.gif';
import BackgroundImage1 from '../../assets/imgs/kaaba-in-mecca1.jpg';
import BackgroundImage2 from '../../assets/imgs/kaaba-in-mecca2.jpg';
import { HeroBtn } from '../../components';

import './hero-section.scss';

function HeroSection() {
  const { t } = useTranslation();
  const bgImageUrls = useRef([BackgroundImage1, BackgroundImage2, BackgroundImage3]);
  const bgImageUrlsIndex = useRef(0);
  const [currentBgImageUrl, setCurrentBgImageUrl] = useState(bgImageUrls[bgImageUrlsIndex]);

  useEffect(() => {
    bgImageUrls.current.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
    setCurrentBgImageUrl(bgImageUrls.current[bgImageUrlsIndex.current]);
    const intervalId = setInterval(() => {
      bgImageUrlsIndex.current++;
      if (bgImageUrlsIndex.current >= bgImageUrls.current.length) {
        bgImageUrlsIndex.current = 0;
      }

      setCurrentBgImageUrl(bgImageUrls.current[bgImageUrlsIndex.current]);
    }, 4000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <section className="hero-section" style={{ backgroundImage: `url(${currentBgImageUrl})` }}>
      <div className="hero-section__content">
        <h2>{t('content.hero-services-description')}</h2>
        <HeroBtn caption={t('content.hero-reach-out-btn-caption')} />
      </div>
    </section>
  );
}
export default HeroSection;

import React from 'react';

import { useTranslation } from 'react-i18next';

import { CartAddBtn } from '../../components';
import { WHATSAPP_NUMBER } from '../../constansts';
import { CarReversationByWhatsappBtn, FromToTour } from '../../containers';

function FromToTourPrice({ transport }) {
  const { t } = useTranslation();
  return (
    <FromToTour fromTo={transport}>
      <div className="container">
        <div className="row">
          <div className="col">
            {t('content.transport-for-car-price')}:<span className="fs-1 fw-bold px-3">{transport.price}</span>
            {t('content.currency')}
            <div className="fs-4 fw-bold text-success">{t('content.tax-free')}</div>
          </div>
          <div className="col">
            {t('content.transport-for-car-down-payment')}:<span className="fs-1 fw-bold px-3">{transport.downPayment}</span>
            {t('content.currency')}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 col-md-3">
          <CartAddBtn classname="btn-primary" car={transport.car} transport={transport} />
        </div>
        <div className="col-2 col-md-6"></div>
        <div className="col-5 col-md-3">
          <CarReversationByWhatsappBtn classname="btn-outline-primary" car={transport.car} transport={transport} tel={WHATSAPP_NUMBER} />
        </div>
      </div>
    </FromToTour>
  );
}

export default FromToTourPrice;

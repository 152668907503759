import React from 'react';

import './from-to-tour.scss';
import { Bag as TourIcon, CarFrontFill as Icon1, BusFront as Icon2 } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

function FromToTour({ children, fromTo }) {
  const { t } = useTranslation();
  return (
    <div className="col-md-4">
      <div className="from-to-tour-container position-relative">
        <div className={`container-fluid bg-secondary text-primary rounded pt-3 pb-3`}>
          <div className="row ">
            <div className="col-3 d-flex justify-content-center align-items-center">
              <TourIcon size={28} />
            </div>
            <div className="col-9">
              <h1 className="fs-5 text-center">{t('content.transport-type-tour')}</h1>
              <h2 className="fs-5 text-center">{'\u00A0'}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-start align-items-start">
              <Icon1 size={28} />
              {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}
              <Icon2 size={28} />
            </div>
          </div>
          <div className="row">
            <div className="col-3 d-flex justify-content-center align-items-center"></div>
            <div className="col-9">
              <h1 className="fs-5 text-center">{t(`city.${fromTo.from}`)}</h1>
              <h2 className="fs-5 text-center">{t(`place.${fromTo.fromPlace}`)}</h2>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default FromToTour;

import React, { useRef } from 'react';

import { FromToPrice, FromToTourPrice } from '../../containers';
import { transportService } from '../../services';

function TransportGallarySection({ car }) {
  const serviceForTransport = useRef(transportService);
  return (
    <section className="transport-gallary-section bg-primary">
      <div className="container-fluid mt-3">
        <div className="row g-3">{serviceForTransport.current.rawForCar(car).map((item, index) => (item.type === transportService.transportType.TRANSPORT ? <FromToPrice key={index} transport={item} /> : <FromToTourPrice key={index} transport={item} />))}</div>
      </div>
    </section>
  );
}
export default TransportGallarySection;

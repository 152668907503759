const CART_STORAGE_KEY = 'cart-storage-key';
const TIKTOK_URL = 'https://www.tiktok.com/@mawkibalakabir';
const TWITTER_URL = 'https://twitter.com/mwkbalakabr?t=eEH2ody9H1q0VE4UevPqvA';
const WHATSAPP_NUMBER = '+966 566 082 067';
const INSTAGRAM_URL = 'https://www.instagram.com/mwkblakbr/?igshid=NTc4MTIwNjQ2YQ';
const YOUTUBE_URL = 'https://youtube.com/@user-xn6cx2hx1s?si=eiP4lPRQLhoDeBxI';
const GMAIL_URL = 'mailto:mwkbalakabr@gmail.com';

const MAROUF_URL = 'https://maroof.sa/businesses/details/287842';

const COMMERCIAL_REGISTER = '4031277710';
const TAX_IDENTIFICATION = '311164786100003';

const EMAIL_SENDER_URL = 'https://mawkibalakabir.com/sender.php';

export { CART_STORAGE_KEY, TIKTOK_URL, TWITTER_URL, WHATSAPP_NUMBER, INSTAGRAM_URL, YOUTUBE_URL, GMAIL_URL, MAROUF_URL, COMMERCIAL_REGISTER, TAX_IDENTIFICATION, EMAIL_SENDER_URL };

import { useContext } from 'react';

import { CartContext } from '../contexts/CartContex';

const useCart = () => {
  return {
    ...useContext(CartContext),
  };
};

export default useCart;
